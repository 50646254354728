@tailwind base;
@tailwind components;
@tailwind utilities;

.button {
  font-family: inherit;
  background: #ea0b00;
  color: white;
  padding: 0.35em 0;
  font-size: 17px;
  border: none;
  border-radius: 0.7em;
  letter-spacing: 0.08em;
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  overflow: hidden;
  height: 2.5em;
  padding-left: 2.8em;
  padding-right: 0.92em;
}

.button .icon {
  background: #fff;
  height: 2em;
  width: 2em;
  border-radius: 2em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0.4em;
  transition: all 0.5s;
}

.icon svg {
  margin-left: 0.1em;
  transition: all 0.5s;
  color: red;
  width: 1.2rem;
  height: 1.2rem;
}

.button:hover .icon svg {
  transform: rotate(360deg);
}

.button:hover .icon {
  width: calc(100% - 0.85rem);
  border-radius: 0.5em;
}

.curve-underline {
  position: relative;
  text-decoration: none;
}

.curve-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  border-top: solid 2px #ea0b00;
  left: 0;
  bottom: -10px;
  border-radius: 50%;
  height: 8px;
}
